<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useDetail } from '~/store/detail'

interface Props {
  jobId: number
  reportRedirect: boolean
  icon?: string
  withTitle?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  icon: 'mdi:information',
  withTitle: true,
})
const emit = defineEmits(['modalClosed'])
const config = useRuntimeConfig()
const { t } = useI18n()
const detail = useDetail()
const route = useRoute()
const router = useRouter()
const isError = ref()
const isOpen = ref(false)
const reportDone = ref(false)
const isLoading = ref(false)
const token = useCookie('token', { path: '/', domain: config.public.COOKIE_DOMAIN })
const reportOpt = [
  { value: 'spam', label: t('modal_report_opt_spam') },
  { value: 'expired', label: t('modal_report_opt_expired') },
  { value: 'not_relevant', label: t('modal_report_opt_not_relevant') },
  { value: 'not_suitable', label: t('modal_report_opt_not_suitable') },
  { value: 'dont_like', label: t('modal_report_opt_dont_like') },
]
const selectedReport = ref()
function closeModal() {
  isOpen.value = false
  emit('modalClosed')
  if (props.reportRedirect) {
    if (route.path.split('/')[1] !== 'dashboard' && reportDone.value) {
      detail.resetSelectedJob()
      if (route.path.split('/')[1] === 'job')
        router.go(-1)
    }
  }
}
function openModal() {
  isOpen.value = true
}

async function reportJob() {
  isLoading.value = true
  const { data, error } = await useCompanyApi('/talent/job/report', {
    method: 'POST',
    body: { type: route.path.split('/')[1] !== 'dashboard' ? 'browser_job' : 'job_slot', reason: selectedReport.value, jobId: props.jobId },
    initialCache: false,
    useAuth: !!token.value,
  })
  const resp = data?.value as { success: boolean }
  isLoading.value = false
  if (resp.success) {
    if (route.path.split('/')[1] === 'dashboard') {
      detail.setDeletedJobId(props.jobId)
      detail.resetSelectedJob()
      closeModal()
    }
    else {
      reportDone.value = true
    }
  }
  else { isError.value = resp?.error || error.value?.data?.error }
}
</script>

<template>
  <div class="flex-shrink-0 flex items-center cursor-pointer" @click="openModal">
    <Icon :name="props.icon" :class="`${props.withTitle ? 'mr-2' : ''} w-5 h-5 text-indigo-500`" />
    <span v-if="props.withTitle">{{ $t('modal_report_dropdown_title') }}</span>
  </div>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30" @close="closeModal">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" style="backdrop-filter: blur(10px)" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all text-sm">
              <div v-if="!reportDone">
                <div v-if="!isError">
                  <p class="text-2xl font-bold">
                    {{ $t('modal_report_title') }}
                  </p>
                  <div class="py-10">
                    <div v-for="(item, idx) in route.path.split('/')[1] !== 'dashboard' ? reportOpt.slice(0, -2) : reportOpt" :key="idx" class="flex items-center mb-6">
                      <input v-model="selectedReport" type="radio" name="inputLocation" :value="item.value" class="mr-2"> {{ item.label }}
                    </div>
                    <div>
                      {{ $t('modal_report_desc_1') }}
                      <a href="https://tally.so/r/woeEob" target="_blank" class="underline">{{ $t('modal_report_desc_2') }}</a>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  <Icon name="mdi:alert" class="w-12 h-12 text-yellow-500" />
                  <p class="text-2xl font-bold mb-4">
                    {{ $t('global_something_went_wrong') }}
                  </p>
                  <p class="mb-20">
                    {{ $t('modal_report_try_again') }}
                  </p>
                </div>
                <div class="flex items-center">
                  <BaseAtomsButton type="button" size="sm" class="capitalize focus:ring-0 focus:ring-offset-0 font-bold bg-transparent hover:bg-transparent text-indigo-500 shadow-0 p-2.5 mr-6 ml-auto" @click="closeModal">
                    {{ $t('global_cancel') }}
                  </BaseAtomsButton>
                  <BaseAtomsButton type="button" size="sm" :disabled="selectedReport ? false : true" :class="`${!selectedReport ? '!bg-transparent !hover:bg-transparent text-gray-400' : ''} capitalize focus:ring-0 focus:ring-offset-0 font-bold shadow-0 p-2.5`" @click="reportJob()">
                    <span v-if="!isLoading">{{ $t('global_send') }}</span>
                    <div v-else class="text-white">
                      <Icon name="eos-icons:loading" class="h-5 w-5 mr-1" aria-hidden="true" /> {{ $t('global_loading') }}
                    </div>
                  </BaseAtomsButton>
                </div>
              </div>
              <div v-else class="text-center">
                <p class="text-2xl font-bold mb-4">
                  {{ $t('modal_report_thank_you') }}
                </p>
                <p class="mb-20">
                  {{ $t('modal_report_team_will_evaluate') }}
                </p>

                <BaseAtomsButton type="button" size="sm" class="capitalize focus:ring-0 focus:ring-offset-0 font-bold bg-gray-50 hover:bg-gray-50 text-indigo-500 shadow-0 p-2.5 w-full" @click="closeModal">
                  {{ $t('global_close') }}
                </BaseAtomsButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
